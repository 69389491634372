import { debounce } from './util'
import { DOMReady } from './lib/domready'

const eye =
  '<svg width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.134 13.134 0 001.66 2.043C4.12 11.332 5.88 12.5 8 12.5c2.12 0 3.879-1.168 5.168-2.457A13.134 13.134 0 0014.828 8a13.133 13.133 0 00-1.66-2.043C11.879 4.668 10.119 3.5 8 3.5c-2.12 0-3.879 1.168-5.168 2.457A13.133 13.133 0 001.172 8z"></path><path fill-rule="evenodd" d="M8 5.5a2.5 2.5 0 100 5 2.5 2.5 0 000-5zM4.5 8a3.5 3.5 0 117 0 3.5 3.5 0 01-7 0z"></path></svg>'
const eyeDash =
  '<svg width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 00-2.79.588l.77.771A5.944 5.944 0 018 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0114.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"></path><path fill-rule="evenodd" d="M11.297 9.176a3.5 3.5 0 00-4.474-4.474l.823.823a2.5 2.5 0 012.829 2.829l.822.822zm-2.943 1.299l.822.822a3.5 3.5 0 01-4.474-4.474l.823.823a2.5 2.5 0 002.829 2.829z"></path><path fill-rule="evenodd" d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 001.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 018 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709z"></path><path fill-rule="evenodd" d="M13.646 14.354l-12-12 .708-.708 12 12-.708.708z"></path></svg>'
const invalidCheckmark =
  '<svg width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 15A7 7 0 108 1a7 7 0 000 14zm0 1A8 8 0 108 0a8 8 0 000 16z"></path><path fill-rule="evenodd" d="M11.854 4.146a.5.5 0 010 .708l-7 7a.5.5 0 01-.708-.708l7-7a.5.5 0 01.708 0z"></path><path fill-rule="evenodd" d="M4.146 4.146a.5.5 0 000 .708l7 7a.5.5 0 00.708-.708l-7-7a.5.5 0 00-.708 0z"></path></svg>'
const validCheckmark =
  '<svg width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 15A7 7 0 108 1a7 7 0 000 14zm0 1A8 8 0 108 0a8 8 0 000 16z"></path><path fill-rule="evenodd" d="M10.97 4.97a.75.75 0 011.071 1.05l-3.992 4.99a.75.75 0 01-1.08.02L4.324 8.384a.75.75 0 111.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 01.02-.022z"></path></svg>'

DOMReady(main)

/**
 * Adds a reveal button next to a password input.
 *
 * @param {HTMLInputElement} input The password input
 * @returns {HTMLSpanElement} The created reveal button
 */
function addRevealButton(input) {
  let parent = input.parentNode
  input.setAttribute('data-reveal-password', 'true')
  const revealButton = document.createElement('span')
  revealButton.classList.add('reveal-button')
  revealButton.classList.add('reveal-button-closed')
  revealButton.innerHTML = eye
  revealButton.addEventListener('click', function (eArg) {
    let input = revealButton.previousSibling

    if (input.type === 'password') {
      input.type = 'text'
      revealButton.innerHTML = eyeDash
    } else {
      input.type = 'password'
      revealButton.innerHTML = eye
    }
    input.focus()
  })

  input.outerHTML =
    '<div class="input-reveal position-relative">' + input.outerHTML + '</div>'
  parent = parent.querySelector('.input-reveal')
  parent.appendChild(revealButton)
}

function handleProgressBar(response, lengthCheckProgress, feedbackWrapper) {
  switch (response.score) {
    case 0:
      lengthCheckProgress.style.width = 5 + '%'
      lengthCheckProgress.className = 'progress-bar bg-danger'
      feedbackWrapper.classList.add('text-danger')
      feedbackWrapper.classList.remove('text-warning')
      feedbackWrapper.innerText = response.feedback
      break
    case 1:
      lengthCheckProgress.style.width = 10 + '%'
      lengthCheckProgress.className = 'progress-bar bg-warning'
      feedbackWrapper.classList.remove('text-danger')
      feedbackWrapper.classList.add('text-warning')
      feedbackWrapper.innerText = response.feedback
      break
    case 2:
      lengthCheckProgress.style.width = 30 + '%'
      lengthCheckProgress.className = 'progress-bar bg-warning'
      feedbackWrapper.classList.remove('text-danger')
      feedbackWrapper.classList.add('text-warning')
      feedbackWrapper.innerText = response.feedback
      break
    case 3:
      lengthCheckProgress.style.width = 60 + '%'
      lengthCheckProgress.className = 'progress-bar bg-success'
      feedbackWrapper.classList.remove('text-danger')
      feedbackWrapper.classList.remove('text-warning')
      feedbackWrapper.innerText = response.feedback
      break
    case 4:
      lengthCheckProgress.style.width = 100 + '%'
      lengthCheckProgress.className = 'progress-bar bg-success'
      feedbackWrapper.classList.remove('text-danger')
      feedbackWrapper.classList.remove('text-warning')
      feedbackWrapper.innerText = response.feedback
      break
    default:
      lengthCheckProgress.style.width = 0 + '%'
      lengthCheckProgress.className = 'progress-bar'
      feedbackWrapper.classList.remove('text-danger')
      feedbackWrapper.classList.remove('text-warning')
      feedbackWrapper.innerText = ''
  }
}

/**
 * Scores the password's strength through our API
 */
function fetchStrongPassword(input, lengthCheckProgress, feedbackWrapper) {
  const url = input.dataset.strongPasswordCheckUrl
  if (input.value) {
    const body = JSON.stringify({
      password: input.value,
    })

    return fetch(url, { method: 'POST', body })
      .then((response) => response.json())
      .then((response) =>
        handleProgressBar(response, lengthCheckProgress, feedbackWrapper),
      )
      .catch((error) => console.error(error))
  }
}

/**
 * Adds a strength checker popover on a password input.
 *
 * @param {HTMLInputElement} input The password whose strength to check
 * @returns {HTMLDivElement} A reference to the created popover
 */
function addStrengthChecker(input) {
  const progressElement = document.createElement('div')
  progressElement.classList.add('progress')
  progressElement.classList.add('my-4')
  progressElement.classList.add('h-var')
  progressElement.style.setProperty('--ui-h', '6px')

  const feedbackWrapper = document.createElement('div')
  feedbackWrapper.classList.add('mt-4')

  const lengthCheckProgress = document.createElement('div')
  lengthCheckProgress.classList.add('progress-bar')
  progressElement.appendChild(lengthCheckProgress)

  input.addEventListener(
    'input',
    debounce(() => {
      fetchStrongPassword(input, lengthCheckProgress, feedbackWrapper)
    }, 300),
  )

  input.parentNode.parentNode.insertBefore(
    progressElement,
    input.parentNode.nextSibling,
  )

  input.parentNode.parentNode.insertBefore(
    feedbackWrapper,
    input.parentNode.nextSibling,
  )
}

function main() {
  const passwordInputs = document.querySelectorAll(
    'input[type=password]:not([no-checker])',
  )
  for (const passwordInput of passwordInputs) {
    addRevealButton(passwordInput)
    if (passwordInput.hasAttribute('data-strength-check')) {
      const passwordInputsStrongCheck = document.querySelectorAll(
        'input[type=password][data-strength-check]',
      )
      for (const passwordInputStrongCheck of passwordInputsStrongCheck) {
        addStrengthChecker(passwordInputStrongCheck)
      }
    }
  }
}
