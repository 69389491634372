import { DOMReady } from './lib/domready'
DOMReady(main)

export function countWidgets(nodes, url) {
  const keyNode = document.querySelector('#google-recaptcha-v3')
  if (!keyNode) {
    return
  }

  const refs = []
  for (const node of nodes) {
    refs.push(node.dataset.ref)
  }
  if (!refs.length) {
    return
  }

  setTimeout(() => {
    if (typeof grecaptcha !== 'undefined') {
      grecaptcha.ready(function () {
        const key = keyNode.dataset.key
        const action = { action: 'widget_view' }

        grecaptcha.execute(key, action).then(function (token) {
          fetch(url, {
            method: 'PUT',
            body: JSON.stringify({ refs, token }),
          }).catch(() => {
            // we do not care about the response, we're just using this to catch errors that would clog logs
          })
        })
      })
    }
  }, 1000)
}

function main() {
  const boatWidgets = document.querySelectorAll(
    '[data-boat-widget-view-counter][data-ref]'
  )
  const officeWidgets = document.querySelectorAll(
    '[data-office-widget-view-counter][data-ref]'
  )
  const lang = document.querySelector('html').lang

  // URL is hardcoded because I didn't want to put it directly in the HTMl, to limit false positives
  // We are not interested in the response, we just want to ping the server
  const boatUrl = `/${lang}/public-api/views/boat-widget`
  const officeUrl = `/${lang}/public-api/views/office-widget`

  countWidgets(boatWidgets, boatUrl)
  countWidgets(officeWidgets, officeUrl)
}
