import Translator from './translator'
import { decrementWishCount, incrementWishCount } from './lib/user-list-counts'
import { DOMReady } from './lib/domready'

DOMReady(main)

document.addEventListener('FragmentWishButtonLoaded', (e) => {
  const locale = document.querySelector('html').lang
  // on some pages we can have duplicate ads
  const hearts = document.querySelectorAll(
    `input[data-wish-ad="${e.detail.adId}"]`
  )
  for (const heart of hearts) {
    attachWishButton(heart, locale)
  }
})

document.addEventListener('FragmentWishButtonContainerLoaded', (e) => {
  const locale = document.querySelector('html').lang
  const container = document.querySelector(e.detail.container)
  if (!container) {
    return
  }

  const hearts = container.querySelectorAll(`input[data-wish-ad]`)
  for (const heart of hearts) {
    attachWishButton(heart, locale)
  }
})

let handleWishlistChange = function (evt, locale) {
  if (evt.target.checked) {
    let url = `/${locale}/account/profile/wish/add/${evt.target.dataset.wishAd}`
    let init = { method: 'POST' }
    call(url, init, evt)
  } else {
    let url = `/${locale}/account/profile/wish/delete/${evt.target.dataset.wishAd}`
    let init = { method: 'DELETE' }
    call(url, init, evt)
    if (document.querySelector('#remove_wish_card_on_delete')) {
      const wrapper = evt.target.closest('[data-wish-card]')
      const card = wrapper.querySelector('.bs-card')
      const cancel = wrapper.querySelector('.alert-success')
      card.classList.add('hidden')
      cancel.classList.remove('hidden')
      const button = cancel.querySelector('a')
      button.onclick = function (e) {
        e.preventDefault()
        evt.target.checked = true
        let url = `/${locale}/account/profile/wish/add/${evt.target.dataset.wishAd}`
        let init = { method: 'POST' }
        call(url, init, evt)
        card.classList.remove('hidden')
        cancel.classList.add('hidden')
        return false
      }
    }
  }
}

function attachWishButton(heart, locale) {
  if (heart.dataset.wishButtonAttached) {
    return
  }

  if (heart.checked) {
    toggleHeartFill(heart, true)
  } else {
    toggleHeart(heart, true)
  }

  heart.dataset.wishButtonAttached = true
  heart.addEventListener('change', (evt) => {
    fetch(window.urls.accountStatus, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      response.json().then((result) => {
        if (result.isLoggedIn) {
          handleWishlistChange(evt, locale)
        } else {
          Translator.then((translator) => {
            let event = new CustomEvent('must-login', {
              detail: {
                message: translator.trans(
                  'front.user_panel.must_login.message.wish_add'
                ),
                successCallback: () => {
                  handleWishlistChange(evt, locale)
                },
                redirectUrl: document.location.toString(),
              },
            })
            document.dispatchEvent(event)
          })
        }
      })
    })
  })

  const label = heart.labels[0]
  label.classList.remove('hidden')
  document.querySelector(`#${label.htmlFor}-spinner`).remove()
}

export function attachWishButtons() {
  const locale = document.querySelector('html').lang
  const hearts = document.querySelectorAll('input[data-wish-ad]')
  if (hearts.length) {
    hearts.forEach((heart) => {
      attachWishButton(heart, locale)
    })
  }
}

function main() {
  attachWishButtons()
}

function toggleDisplay(input, selector, display) {
  if (!input.labels || !(0 in input.labels)) {
    return
  }

  const label = input.labels[0]
  const node = label.querySelector(selector)
  if (node) {
    if (display) {
      node.classList.remove('hidden')
    } else {
      node.classList.add('hidden')
    }
  }
}

function toggleHeart(input, display) {
  toggleDisplay(input, '.heart', display)
  toggleDisplay(input, '.heart-fill-empty', display)
}

function toggleHeartFill(input, display) {
  toggleDisplay(input, '.heart-fill', display)
}

function toggleHearts(input, display) {
  toggleHeart(input, display)
  toggleHeartFill(input, display)
}

function toggleSpinner(input, display) {
  toggleDisplay(input, '.spinner-border', display)
}

function call(url, init, evt) {
  const inputs = document.querySelectorAll(`[data-wish-ad="${evt.target.dataset.wishAd}"]`)
  for (const input of inputs) {
    toggleHearts(input, false)
    toggleSpinner(input, true)
    input.checked = evt.target.checked
  }

  fetch(url, init)
    .then((res) => {
      if (res.ok) {
        res.json().then((response) => {
          if (response.hasOwnProperty('must_login')) {
            const event = new CustomEvent('must-login', {
              detail: {
                message: response.login_message,
                redirectUrl: url,
              },
            })
            document.dispatchEvent(event)
            for (const input of inputs) {
              input.checked = false
              toggleSpinner(input, false)
            }
          } else if (response.hasOwnProperty('wish_add_confirm')) {
            for (const input of inputs) {
              toggleSpinner(input, false)
              toggleHeartFill(input, true)
            }
            incrementWishCount()
            if (evt.target.labels && 0 in evt.target.labels) {
              const label = evt.target.labels[0]
              if (label) {
                const output = label.querySelector('span.d-block')
                if (output) {
                  const html = output.dataset.wishRemoveText
                  if (html) {
                    output.innerHTML = html
                  }
                }
              }
            }
            window.sendinblue.track(
              'wishlist_updated',
              response.user_data,
              response.data
            )
            for (const datum of response.additionalData) {
              window.sendinblue.track(
                datum.event_name,
                response.user_data,
                datum.event_data
              )
            }
          } else if (response.hasOwnProperty('wish_delete_confirm')) {
            let confirmBox = document.querySelector(
              'span#ad-show-wish-confirm-message'
            )
            if (confirmBox) {
              confirmBox.innerHTML = response.wish_delete_confirm
              confirmBox.parentElement.classList.remove('hidden')
              if (document.querySelector('input#toggle-heart'))
                document.querySelector('input#toggle-heart').checked = false
              if (document.querySelector('input#toggle-heart-card'))
                document.querySelector(
                  'input#toggle-heart-card'
                ).checked = false
            }
            for (const input of inputs) {
              toggleSpinner(input, false)
              toggleHeart(input, true)
            }
            decrementWishCount()
            if (evt.target.labels && 0 in evt.target.labels) {
              const label = evt.target.labels[0]
              if (label) {
                const output = label.querySelector('span.d-block')
                if (output) {
                  const html = output.dataset.wishAddText
                  if (html) {
                    output.innerHTML = html
                  }
                }
              }
            }
            window.sendinblue.track(
              'wishlist_updated',
              response.user_data,
              response.data
            )
          }
        })
      } else {
        return res.json().then((err) => {
          throw err
        })
      }
    })
    .catch((err) => {
      throw err
    })
}
