import {
  clearError,
  setError,
  setMessage,
  clearMessage,
} from './lib/input-error'
import { DOMReady } from './lib/domready'

DOMReady(main)

function main() {
  const emailInputs = document.querySelectorAll('[data-email-invite]')
  for (const emailInput of emailInputs) {
    const sendInvite = () => {
      if (!emailInput.value) {
        setError(emailInput, emailInput.dataset.emailInviteErrorEmpty)
        return
      }

      const input = `${emailInput.dataset.emailInviteUrl}/${emailInput.value}`
      const init = { method: 'POST' }
      fetch(input, init)
        .then((res) => {
          clearError(emailInput)
          clearMessage(emailInput)
          if (res.ok) {
            res.json().then((response) => {
              setMessage(emailInput, response.message)
            })
            emailInput.value = ''
            emailInput.focus()
          } else {
            return res.json().then((err) => {
              throw err
            })
          }
        })
        .catch((err) => {
          setError(emailInput, err.message)
        })
    }

    const inputGroup = document.createElement('div')
    inputGroup.classList.add('input-group')

    const inputGroupAppend = document.createElement('div')
    inputGroupAppend.classList.add('input-group-append')

    const sendButton = document.createElement('button')
    sendButton.type = 'button'
    sendButton.innerText = emailInput.dataset.emailInviteLabel
    sendButton.classList.add('btn')
    sendButton.classList.add('btn-outline-primary')
    sendButton.dataset.id = 'form-btn-invitation'
    sendButton.addEventListener('click', sendInvite)

    emailInput.addEventListener('keydown', function (e) {
      if (e.code === 'Enter') {
        e.preventDefault()
        sendInvite()
      }
    })

    emailInput.parentNode.insertBefore(inputGroup, emailInput)
    inputGroup.appendChild(emailInput)

    inputGroupAppend.appendChild(sendButton)
    emailInput.parentNode.appendChild(inputGroupAppend)
  }
}
