import { DOMReady } from './lib/domready'

DOMReady(main)

function main() {
  const inputs = document.querySelectorAll('[data-email-verification-button]')
  for (const input of inputs) {
    input.addEventListener('click', function () {
      const url = input.dataset.emailVerificationUrl
      if (!url) {
        return
      }
      fetch(url, { method: 'POST' })
        .then((res) => {
          if (!res.ok) {
            return res.json().then((json) => {
              throw json
            })
          }
        })
        .catch((err) => {
          window.alert(err.message)
        })
    })
  }
}
