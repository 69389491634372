import { addBadge } from './badge'

DOMReady(main)

import './badge'
import { DOMReady } from './lib/domready'

function main() {
  const badgeInputs = document.querySelectorAll('[data-badge-source]')
  for (const input of badgeInputs) {
    const source = document.querySelector(input.dataset.badgeSource)
    if (!source) {
      continue
    }
    for (const item of JSON.parse(source.value)) {
      addBadge(
        input,
        item.label,
        () => {
          const oldValue = JSON.parse(source.value)
          const newValue = oldValue.filter((i) => i.id !== item.id)
          source.value = JSON.stringify(newValue)
        },
        item.theme
      )
    }
  }
}
