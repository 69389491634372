export function updateUserListCount(container, action) {
  const evt = new CustomEvent('updateUserListCount', {
    detail: { container, action },
  })
  document.dispatchEvent(evt)
}

export function incrementSearchCount() {
  updateUserListCount('search', 'increment')
}
export function decrementSearchCount() {
  updateUserListCount('search', 'decrement')
}

export function incrementPriceCount() {
  updateUserListCount('price', 'increment')
}
export function decrementPriceCount() {
  updateUserListCount('price', 'decrement')
}

export function incrementWishCount() {
  updateUserListCount('wish', 'increment')
}
export function decrementWishCount() {
  updateUserListCount('wish', 'decrement')
}
