import '../css/badge.css'

/**
 * Creates a badge holder into the specified element and returns a
 * reference to it.
 *
 * A badge holder has the following template:
 * <div class="badge-holder"></div>
 *
 * @param {Element} element
 * @returns {HTMLDivElement}
 */
export function addBadgeHolder(element) {
  const badgeHolder = document.createElement('div')
  badgeHolder.classList.add('badge-holder')
  element.appendChild(badgeHolder)
  return badgeHolder
}

/**
 * Adds a self-destructing badge to the specified holder.
 *
 * A badge has the following template:
 * <span class="badge">
 *     <span class"badge-label">{label}</span>
 *     <span class="badge-delete-button">[x]</span>
 * </span>
 *
 * @param {Element} appendTo Where to append the badge to; typically a badge holder
 * @param {string} label The badge's label
 * @param {function} onDelete The callback that happens after clicking the delete button
 * @param {string} theme The theme for the badge
 */
export function addBadge(appendTo, label, onDelete, theme) {
  const badge = document.createElement('span')
  const badgeLabel = document.createElement('span')
  const badgeDeleteButton = document.createElement('span')

  badge.classList.add('badge')
  badge.classList.add('bg-' + (theme || 'primary'))
  badge.classList.add('d-inline-flex')
  badge.classList.add('justify-content-start')
  badge.classList.add('align-items-center')
  badge.classList.add('py-2')
  badge.classList.add('px-var')
  badge.style.setProperty('--ui-px', '10px')
  badge.classList.add('mb-2')
  badge.classList.add('mr-2')
  badgeLabel.classList.add('badge-label')
  badgeDeleteButton.classList.add('badge-delete-button')
  badgeDeleteButton.classList.add('cursor-pointer')

  badgeLabel.innerHTML = label
  badgeDeleteButton.innerHTML =
    '<svg class="ml-2" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M11.854 4.146a.5.5 0 010 .708l-7 7a.5.5 0 01-.708-.708l7-7a.5.5 0 01.708 0z"></path><path fill-rule="evenodd" d="M4.146 4.146a.5.5 0 000 .708l7 7a.5.5 0 00.708-.708l-7-7a.5.5 0 00-.708 0z"></path></svg>'
  badgeDeleteButton.addEventListener('click', function () {
    badge.parentNode.removeChild(badge)
    onDelete()
  })

  badge.appendChild(badgeLabel)
  badge.appendChild(badgeDeleteButton)
  appendTo.appendChild(badge)
}
