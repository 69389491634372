import { addItem } from './symfony-prototypes'

import { addBadge } from './badge'
import { DOMReady } from './lib/domready'

DOMReady(main)

function main() {
  const inputs = document.querySelectorAll('[data-autocomplete-fallback]')
  for (const input of inputs) {
    const targetSelector = input.dataset.bsTarget
    if (!targetSelector) {
      continue
    }
    const target = document.querySelector(input.dataset.bsTarget)
    if (!target) {
      continue
    }
    input.classList.add('btn-link')
    input.addEventListener('click', function () {
      // if (target.children.length > 0) {
      //   return
      // }
      const item = addItem(target)

      const header = document.createElement('div')
      header.classList.add('card-header')
      header.innerText = input.dataset.headerText
      const closeButton = document.createElement('button')
      closeButton.type = 'button'
      closeButton.classList.add('btn-close')
      closeButton.addEventListener('click', function () {
        item.parentNode.removeChild(item)
      })
      header.appendChild(closeButton)

      const footer = document.createElement('div')
      footer.classList.add('card-footer')
      footer.classList.add('text-right')
      const submitButton = document.createElement('button')
      submitButton.type = 'button'
      submitButton.innerText = input.dataset.submitText
      submitButton.classList.add('btn')
      submitButton.classList.add('btn-outline-primary')
      submitButton.addEventListener('click', function () {
        item.classList.add('hidden')
        const appendTo = document.querySelector(input.dataset.badgeHolderTarget)
        const label = item.querySelector(
          '[data-autocomplete-fallback-label]'
        ).value
        if (label) {
          addBadge(appendTo, label, () => {
            item.parentNode.removeChild(item)
          })
        } else {
          item.parentNode.removeChild(item)
        }
      })
      footer.appendChild(submitButton)

      item.classList.add('card')
      item.firstElementChild.classList.add('card-body')
      item.firstElementChild.classList.add('mb-0')
      item.insertBefore(header, item.firstChild)
      item.appendChild(footer)
    })
  }
}
