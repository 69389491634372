export function sendinblue() {
  const cookies = document.cookie
    .split(';')
    .map((cookie) => cookie.trim())
    .map((cookie) => cookie.split(/=(.*)/))
    .filter((cookie) => cookie)

  const bobConsent = cookies
    .map((a, b) => (a[0] === 'bob_consent' ? a[1] : null))
    .filter((cookie) => cookie)[0]

  const hasConsent =
    !!bobConsent &&
    bobConsent.split('!').filter((cookie) => cookie === 'sendinblue=true')
      .length > 0

  if (hasConsent) {
    var n = document.createElement('script'),
      i = document.getElementsByTagName('script')[0]
    ;(n.type = 'text/javascript'),
      (n.id = 'sendinblue-js'),
      (n.async = !0),
      (n.src = 'https://sibautomation.com/sa.js?key=' + window.sib?.client_key),
      i.parentNode.insertBefore(n, i),
      window.sendinblue?.page()
  }
}
