export default class BoatHistory {
  constructor(storage, key, syncApi) {
    this.storage = storage
    this.key = key
    this.syncApi = syncApi

    try {
      const collection = storage.getItem(key)
      this.collection = collection ? JSON.parse(collection) : []
    } catch (domException) {
      // access to storage interface is denied
      this.collection = []
    }
  }

  _saveCollection() {
    try {
      this.storage.setItem(this.key, JSON.stringify(this.collection))
    } catch (domException) {
      // access to storage interface is denied, do nothing
    }
  }

  _truncateCollection() {
    this.collection = this.collection.slice(-100)
  }

  _merge(collection) {
    this.collection = this.collection.concat(collection)
    this.collection.sort((a, b) => a.timestamp - b.timestamp)
  }

  isSeen(id) {
    return !!this.collection.find((item) => item.id === id)
  }

  see(id, timestamp) {
    const index = this.collection.findIndex((item) => item.id === id)
    if (-1 !== index) {
      this.collection.splice(index, 1)
    }
    this.collection.push({ id, timestamp })
    this._truncateCollection()
    this._saveCollection()
  }

  async synchronize() {
    if (!this.syncApi) {
      return
    }
    const res = await fetch(this.syncApi)
    const json = await res.json()
    this._merge(json)
    this._truncateCollection()
    this._saveCollection()
  }
}
