import * as Sentry from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'
import 'lazysizes'
import './displaytoggles'
import './input-toggles'
import './fallback'
import './password-checker'
import './symfony-prototypes'
import './badge-input'
import './email-invite'
import './email-confirm'
import './addressable'
import './widget-view-counter'
import './wish'
import { bindToggler } from './components/toggle-selector'
import { sendinblue } from './components/sendinblue'
import loader from './pay/loader'
import { Offcanvas } from 'bootstrap'
import breakpoints from './lib/breakpoints'
import BoatHistory from './components/boat-history'
import { emitToast, listenForToast } from './lib/toast'
import { DOMReady } from './lib/domready'
import { datalayerPush } from './lib/datalayer'

DOMReady(() => {
  Sentry.onLoad(() => {
    Sentry.init({
      dsn: process.env.SENTRY_DSN,
      integrations: [
        new BrowserTracing({
          tracingOrigins: ['bandofboats.com/'],
        }),
        new Sentry.Integrations.GlobalHandlers({
          onerror: true,
          onunhandledrejection: false,
        }),
      ],
      environment: process.env.SENTRY_ENVIRONMENT,
      release: process.env.APP_VERSION,
      tracesSampleRate: 0.25,
      ignoreErrors: [
        'ChunkLoadError',
        'top.GLOBALS',
        'originalCreateNotification',
        'canvas.contentDocument',
        'instantSearchSDKJSBridgeClearHighlight',
        'BetterJsPop',
        'NetworkError when attempting to fetch resource',
        'Non-Error promise rejection',
        'ResizeObserver loop limit exceeded',
        'Blocked a frame with origin',
        'NS_ERROR_FILE_CORRUPTED',
        'NS_ERROR_FILE_NOT_FOUND',
        /abtasty/i,
        /adParams/i,
        /Deprecated/i,
        /tarteaucitron is undefined/i,
        /out of memory/i,
        /webkitExitFullScreen/i,
        /The operation is insecure/i,
        /No error message/i,
        /ResizeObserver loop/i,
        /listenToMutationsFromJS/i,
        /Cannot access rules/i,
        /Unexpected end of input/i,
        /Unexpected token 'else'/i,
        /Can't find variable: (tarteaucitron|google_tag_manager|IntersectionObserver)/i,
        /r\["@context"]\.toLowerCase/i,
        /Object\.prototype\.hasOwnProperty\.call\(o,"telephone"\)/i,
        /googletag.cmd/i,
        /extractFilteredSchemaValuesFromJSONLD/i,
        /extractAllSchemaValuesFromJSONLD/i,
        /SyntaxError: No identifiers allowed directly after numeric literal/i,
      ],
      allowUrls: [/(https?:\/\/)?([a-z-]+\.)?bandofboats\.com/],
      denyUrls: [
        /pagead\/js/i,
        /graph\.facebook\.com/i,
        /connect\.facebook\.net\/en_US\/all\.js/i,
        /extensions\//i,
        /^chrome:\/\//i,
        /googletagmanager\.com\/gtm\.js/i,
      ],
      beforeSend: function (event) {
        const ignoreSentryErrors = [
          /abtasty/i,
          /adParams/i,
          /Deprecated/i,
          /tarteaucitron is undefined/i,
          /out of memory/i,
          /webkitExitFullScreen/i,
          /The operation is insecure/i,
          /No error message/i,
          /ResizeObserver loop/i,
          /listenToMutationsFromJS/i,
          /Cannot access rules/i,
          /Unexpected end of input/i,
          /Unexpected token 'else'/i,
          /Can't find variable: (tarteaucitron|google_tag_manager|IntersectionObserver)/i,
          /r\["@context"]\.toLowerCase/i,
          /googletag.cmd/i,
          /SyntaxError: No identifiers allowed directly after numeric literal/i,
        ]
        for (const regex of ignoreSentryErrors) {
          if (
            typeof regex.test !== 'function' ||
            regex.test(event.message) ||
            regex.test(event.title)
          ) {
            return null
          }
        }
        const ignoreFunctionNames = [
          /extractFilteredSchemaValuesFromJSONLD/i,
          /extractAllSchemaValuesFromJSONLD/i,
          /moveSelectionRangeOutsideView/i,
          /global code/i,
          /scan_iframes/i,
        ]
        for (const exception of event.exception?.values ?? []) {
          for (const frame of exception.stacktrace?.frames ?? []) {
            for (const regex of ignoreFunctionNames) {
              if (regex.test(frame.function)) {
                return null
              }
            }
          }
        }
        return event
      },
    })
  })
})

function breakpointEvent(evt) {
  document.body.dataset.breakpoint = evt.detail

  document
    .querySelectorAll('[data-bs-breakpoint-element]')
    .forEach((element) => {
      let toContainer = document.querySelector(
        '[data-bs-breakpoint-receiver=' +
          element.dataset.bsBreakpointElement +
          '][data-bs-breakpoint*=' +
          evt.detail +
          ']',
      )

      if (toContainer) {
        toContainer.appendChild(element)
      }
    })
}

const isOutOfViewport = function (elem) {
  // Get element's bounding
  var bounding = elem.getBoundingClientRect()

  // Check if it's out of the viewport on each side
  var out = {}
  out.top = bounding.top < 0
  out.left = bounding.left < 0
  out.bottom =
    bounding.bottom >
    (window.innerHeight || document.documentElement.clientHeight)
  out.right =
    bounding.right > (window.innerWidth || document.documentElement.clientWidth)
  out.any = out.top || out.left || out.bottom || out.right
  out.all = out.top && out.left && out.bottom && out.right

  return out
}

async function initializeSwiper(elem) {
  const { createSwiper } = await import('./components/swiper')

  createSwiper(elem)
}

async function initializeDatepicker(input) {
  const { updateInput } = await import('./components/datepicker')

  updateInput(input)
}

async function initializeTelInput(input) {
  const { updateInput } = await import('./components/input-tel')

  updateInput(input)
}

async function initializeChoices(input, options) {
  const { updateInput } = await import('./components/choices')

  updateInput(input, options)
}

async function initializeAutocomplete(input) {
  const { updateInput } = await import('./components/autocomplete')

  updateInput(input)
}

async function initializeSeeMore(element) {
  const { updateBlock } = await import('./components/see-more')

  updateBlock(element)
}

async function initializeSeeMoreModal(element) {
  const { updateBlock } = await import('./components/see-more-modal')

  updateBlock(element)
}

async function bindToggleSelector(element) {
  const { bindToggler } = await import('./components/toggle-selector')

  bindToggler(element)
}

async function initializeReviewVotes(element) {
  const { bindUpvote, bindDownvote } = await import('./components/reviews')

  if (element.dataset.toggle === 'upvote') {
    bindUpvote(element)
  }
  if (element.dataset.toggle === 'downvote') {
    bindDownvote(element)
  }
}

async function initializeReviewDeletion(element) {
  const { bindDelete } = await import('./components/reviews')
  bindDelete(element)
}

DOMReady(() => {
  document.querySelectorAll('.choices-form-input').forEach((input) => {
    initializeChoices(input, {
      removeItemButton: true,
      searchEnabled: false,
      classNames: {
        inputCloned: 'hidden',
      },
      itemSelectText: '',
      noChoicesText: '',
    })
  })

  window.addEventListener('new.bs.breakpoint', breakpointEvent)
  window.addEventListener('init.bs.breakpoint', breakpointEvent)

  breakpoints.init()

  document.querySelectorAll('.toggle-search-bar').forEach((node) => {
    node.addEventListener('click', (event) => {
      const searchContainer = document.querySelector('.search-container')
      if (searchContainer.classList.contains('d-none')) {
        searchContainer.classList.remove('d-none')
      } else {
        searchContainer.classList.add('d-none')
      }
    })
  })

  document
    .querySelectorAll('input[data-autocomplete-url]')
    .forEach(initializeAutocomplete)

  document
    .querySelectorAll('[data-toggle="delete-review"]')
    .forEach(initializeReviewDeletion)

  document
    .querySelectorAll('input[type=text][role=dialog][data-date]')
    .forEach(initializeDatepicker)

  document.querySelectorAll('.view-more-collapse').forEach(initializeSeeMore)
  document.querySelectorAll('.view-more-modal').forEach(initializeSeeMoreModal)

  document.querySelectorAll('[data-swiper]').forEach(initializeSwiper)

  document
    .querySelectorAll('[data-toggle="selector"]')
    .forEach(bindToggleSelector)

  document.querySelectorAll('input[type="tel"]').forEach(initializeTelInput)

  document.querySelectorAll('[data-toggle="element"]').forEach((node) => {
    node.addEventListener('click', (evt) => {
      const targets = document.querySelectorAll(evt.target.dataset.bsTarget)
      targets.forEach((target) => target.classList.toggle('hidden'))
    })
  })

  document.querySelectorAll('[data-toggle="child"]').forEach((trigger) => {
    trigger.addEventListener('click', (evt) => {
      const titleTo = document.querySelector(trigger.dataset.toggleTitleTo)
      if (titleTo) {
        titleTo.innerText = trigger.innerText
      }

      const siblings = document.querySelectorAll(
        '[data-toggle-parent="' + evt.target.dataset.toggleParent + '"]',
      )
      siblings.forEach((sibling) => {
        sibling.classList.remove('active')
        const siblingTargets = document.querySelectorAll(
          sibling.dataset.bsTarget,
        )
        siblingTargets.forEach((siblingTarget) => {
          siblingTarget.classList.add('hidden')
          siblingTarget.classList.remove('show')
          siblingTarget.classList.remove('active')
        })
      })

      const targets = document.querySelectorAll(trigger.dataset.bsTarget)
      targets.forEach((target) => {
        target.classList.remove('hidden')
        target.classList.add('show')
        target.classList.add('active')
      })

      trigger.classList.add('active')
    })
  })

  document
    .querySelectorAll(
      '.dropdown-menu option, .dropdown-menu select, .dropdown-menu input',
    )
    .forEach((node) =>
      node.addEventListener('click', (e) => e.stopPropagation()),
    )

  const bh = new BoatHistory(window.localStorage, 'boat-history')
  document
    .querySelectorAll('[data-boat-history-opacity]')
    .forEach((element) => {
      const id = element.dataset.boatHistoryOpacity
      if (bh.isSeen(id)) {
        element.classList.add('opacity-5')
      }
    })

  // Fix CLS !
  // setTimeout(
  //   () => (document.body.parentElement.style.overflowX = 'initial'),
  //   500
  // )

  document.body.addEventListener('click', (eArg) => {
    let isOut,
      alerted = [],
      reported
    if (eArg.target.tagName === 'BUTTON' && eArg.target.type === 'submit') {
      let form = eArg.target.closest('form')
      if (form) {
        if (!form.checkValidity()) {
          let submitter = eArg.submitter

          if (
            !eArg.submitter ||
            (eArg.submitter && !eArg.submitter.formNoValidate)
          ) {
            form.querySelectorAll(':invalid').forEach((invalid) => {
              isOut = isOutOfViewport(invalid)

              if (isOut.any) {
                if (!alerted[invalid.name]) {
                  invalid.reportValidity()
                  invalid.parentNode.scrollIntoView(true)
                  setTimeout(() => {
                    invalid.reportValidity()
                  }, 500)
                }
                alerted[invalid.name] = true

                //reported = true
              } else {
                invalid.reportValidity()
              }
            })
          }
        }
      }
    }
  })

  document.body.addEventListener('submit', (eArg) => {
    let form
    if (eArg.target.type === 'submit') {
      form = eArg.target.closest('form')
    }
    if (eArg.target.tagName === 'FORM') {
      form = eArg.target
    }
    if (form) {
      if (form.classList.contains('no-loader')) {
        return
      }
      let isValid = form.checkValidity()
      if (!isValid && form.formNoValidate) isValid = true
      if (eArg.submitter != void 0) {
        if (!isValid && eArg.submitter.formNoValidate) isValid = true
      }
      if (isValid && !loader.isLoading()) {
        document.body.classList.add('submit-loading')
        loader.show()
      }
    }
  })

  sendinblue()

  if ('loading' in HTMLImageElement.prototype) {
    let images = document.querySelectorAll('img.lazyload:not(.swiper-lazy)')
    images.forEach((image) => {
      image.loading = 'lazy'
    })

    images = document.querySelectorAll(
      'img[loading="lazy"].lazyload:not(.swiper-lazy)',
    )
    images.forEach((image) => {
      if (image.dataset.src) {
        image.src = image.dataset.src
        delete image.dataset.src
      }
      image.classList.remove('lazyload')
    })
  }

  let current_url = new URL(window.location.href)
  if (
    current_url.searchParams.has('form_name') &&
    current_url.searchParams.has('form_value')
  ) {
    document
      .querySelectorAll(
        'input[name="' +
          current_url.searchParams.get('form_name') +
          '"]:checked',
      )
      .forEach((input) => {
        input.checked = false
      })
  }

  // Handles visit ID updates
  if (current_url.searchParams.has('visit_id')) {
    const url = window.urls.updateLastVisit
    if (url) {
      fetch(url, {
        method: 'POST',
        body: JSON.stringify({
          publicId: current_url.searchParams.get('visit_id'),
        }),
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
        },
      }).catch((e) => {
        console.error('Error while updating last visit ID:', e)
      })
    }
  }

  document.querySelectorAll('.update-return-url').forEach((input) => {
    input.addEventListener('change', (eArg) => {
      document.querySelectorAll('a[href].update-url').forEach((a) => {
        let url = new URL(a.href)

        let return_url = url.searchParams.get('return_url')
        if (return_url) {
          return_url = new URL(return_url)
          return_url.searchParams.delete('form_name')
          return_url.searchParams.delete('form_value')
          return_url.searchParams.append('form_name', input.name)
          return_url.searchParams.append('form_value', input.value)
          url.searchParams.set('return_url', return_url.toString())
          a.href = url.toString()
        }
      })
    })

    if (
      current_url.searchParams.get('form_name') == input.name &&
      current_url.searchParams.get('form_value') == input.value
    ) {
      input.checked = true
      input.dispatchEvent(new InputEvent('change'))
    }
  })

  document
    .querySelectorAll('[data-bs-toggle="offcanvas-js"]')
    .forEach((trigger) => {
      trigger.addEventListener('click', (eArg) => {
        let offcanvas = Offcanvas.getOrCreateInstance(
          document.querySelector(trigger.dataset.bsTarget),
        )

        offcanvas.toggle()
      })
    })

  if (
    document.querySelector('body').classList.contains('device-mobile') ||
    document.querySelector('body').classList.contains('device-tablet') ||
    document.querySelector('body').dataset.breakpoint == 'xs' ||
    document.querySelector('body').dataset.breakpoint == 'sm' ||
    document.querySelector('body').dataset.breakpoint == 'md'
  ) {
    window.onbeforeunload = (eArg) => {
      if (!loader.isLoading()) loader.show()
      setTimeout(() => loader.hide(), 5000)
    }

    document
      .querySelectorAll('.dropdown-toggle.mobile-dropdown')
      .forEach((button) => {
        delete button.dataset.bsToggle

        let option = document.createElement('option')
        option.innerText = button.innerText

        let select = document.createElement('select')
        button.querySelector('.select-placeholder').replaceWith(select)

        const left = select.offsetLeft - 1 // -1 : the border !
        const top = select.offsetTop - 1 // -1 : the border !
        select.style.marginLeft = '-' + left + 'px'
        select.style.paddingLeft = left + 'px'
        select.style.marginTop = '-' + top + 'px'
        select.style.paddingTop = top + 'px'

        const right =
          parseInt(window.getComputedStyle(button).width) -
          parseInt(window.getComputedStyle(select).width)
        const bottom =
          parseInt(window.getComputedStyle(button).height) -
          parseInt(window.getComputedStyle(select).height)
        select.style.marginRight = '-' + right + 'px'
        select.style.paddingRight = right + 'px'

        select.style.marginBottom = '-' + bottom + 'px'
        select.style.paddingBottom = bottom + 'px'

        select.style.background = 'transparent'
        select.style.appearance = 'none'
        select.style.outline = 'none'
        select.style.cursor = 'pointer'
        select.style.fontFamily = 'SourceSansPro'
        select.style.fontWeight = '600'
        select.style.zIndex = '1'
        select.style.color = 'var(--primary)'

        select.appendChild(option)
        select.style.maxWidth = window.getComputedStyle(select).width

        button.nextElementSibling
          .querySelectorAll('.dropdown-item')
          .forEach((item) => {
            let option = document.createElement('option')
            option.innerText = item.innerText
            option.dataset.href = item.href
            option.selected =
              item.value !== 0 && item.classList.contains('active')
            select.appendChild(option)
          })

        select.addEventListener('change', (eArg) => {
          let href = eArg.target.querySelector(':checked').dataset.href
          if (href) {
            window.location.href = href
          }
        })
      })
  }

  //https://wd.imgix.net/image/eqprBhZUGfb8WYnumQ9ljAxRrA72/KCIeOsJ0lCWMthBSSBrn.svg
  window.addEventListener(
    'resume',
    (eArg) => {
      if (document.body.classList.contains('submit-loading')) {
        document.body.classList.remove('submit-loading')
        loader.hide()
      }
    },
    { capture: true },
  )

  const loadDynamicFragments = () => {
    document.querySelectorAll('[data-fetch-url]').forEach((area) => {
      const url = area.dataset.fetchUrl
      const reloadTime = area.dataset.reloadTime

      if (url) {
        const fetchFunction = () => {
          fetch(url, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
            .then((response) => {
              if (response.ok) {
                return response.text()
              }
            })
            .then((text) => {
              area.innerHTML = text
              if (area.dataset.volatile) {
                Object.values(area.children).forEach((item) =>
                  area.parentNode.insertBefore(item, area),
                )
              }
              const eventNames = 'fragment-loaded,' + area.dataset.event

              for (const eventName of eventNames.trim(',').split(',')) {
                let eventDetail = {}

                if (area.dataset.eventData) {
                  eventDetail = {
                    ...eventDetail,
                    ...JSON.parse(area.dataset.eventData),
                  }
                }
                ;(area.dataset.volatile ? area.parentNode : area).dispatchEvent(
                  new CustomEvent(eventName, {
                    detail: eventDetail,
                    bubbles: true,
                  }),
                )
              }

              if (area.dataset.volatile) {
                area.remove()
              }
            })
        }
        fetchFunction()
        if (reloadTime) {
          setInterval(fetchFunction, parseInt(reloadTime) * 1000)
        }
      }
    })
  }
  loadDynamicFragments()
  document.addEventListener('user-logged-in', (event) => {
    loadDynamicFragments()
  })

  listenForToast(document)

  if (document.getElementById('btn-manage-ad')) {
    document
      .getElementById('btn-manage-ad')
      .addEventListener('click', (event) => {
        document.getElementById('manage-ad').classList.remove('hidden')
        document
          .getElementsByTagName('body')[0]
          .classList.add('overflow-hidden')
      })
    document
      .getElementById('close-manage-ad')
      .addEventListener('click', (event) => {
        document.getElementById('manage-ad').classList.add('hidden')
        document
          .getElementsByTagName('body')[0]
          .classList.remove('overflow-hidden')
      })
  }

  if (
    document.getElementById('back-to-top') &&
    document.getElementsByTagName('main')[0].clientHeight >
      window.innerHeight * 1.5
  ) {
    document.getElementById('back-to-top').classList.remove('hidden')
  }

  const toast = localStorage.getItem('toast')
  if (typeof toast !== 'undefined' && toast) {
    emitToast(JSON.parse(toast))
    localStorage.removeItem('toast')
  }

  let redirectLocaleCookie = document.cookie
    .split(';')
    .map((cookie) => cookie.trim())
    .map((cookie) => cookie.split(/=(.*)/))
    .filter((cookie) => cookie && cookie[0] === 'redirectLocale')
  if (redirectLocaleCookie.length > 0) {
    let url =
      '/' +
      document.getElementsByTagName('html')[0].getAttribute('lang') +
      '/' +
      'header-language-banner'
    fetch(url, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
      .then((response) => {
        if (response.ok) {
          return response.text()
        }
      })
      .then((text) => {
        document
          .getElementsByClassName('site-header')[0]
          .prepend(document.createRange().createContextualFragment(text))

        document
          .querySelectorAll('.lang-banner .btn-close')
          .forEach((element) => {
            element.addEventListener('click', (eArg) => {
              document.querySelectorAll('.lang-banner').forEach((element) => {
                element.remove()
              })
            })
          })
      })
  }

  document
    .querySelectorAll('#page-annonce-afficher-numero.unseen')
    .forEach((element) => {
      if (element.dataset.mustLogin === undefined) {
        element.addEventListener('click', (event) => {
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: 'newLead',
            leadType: 'phone',
            leadOrigin: 'ad',
            leadSellerType:
              event.target.dataset.isPro === 'true' ? 'pro' : 'individual',
            leadUrl: `${document.location.pathname}/phone-seen`,
          })
        })
      }
    })
  document
    .querySelectorAll('#page-agence-afficher-numero')
    .forEach((element) => {
      if (element.dataset.mustLogin === undefined) {
        element.addEventListener('click', (event) => {
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: 'newLead',
            leadType: 'phone',
            leadOrigin: 'office',
            leadSellerType: '',
            leadUrl: `${document.location.pathname}/phone-seen`.replace(
              '//',
              '/',
            ),
          })
        })
      }
    })
})

DOMReady(() => {
  document
    .querySelectorAll('[data-toggle="upvote"], [data-toggle="downvote"]')
    .forEach(initializeReviewVotes)
}, ['review-action-loaded'])

DOMReady(
  (event) => {
    const originElement = event?.target ?? document
    originElement
      .querySelectorAll('a[data-link]:not([data-must-login=true])')
      .forEach((element) => {
        const link = element.dataset.link
        if (element.dataset.listenerBound) {
          return
        }
        element.dataset.listenerBound = true
        element.addEventListener('click', (eArg) => {
          if (
            eArg.ctrlKey ||
            eArg.which == 2 ||
            eArg.target.getAttribute('target') === '_blank'
          ) {
            const newWindow = window.open(link, '_blank')
            if (newWindow) {
              newWindow.focus()
            }

            if (element.dataset.datalayerEvent) {
              const data = JSON.parse(element.dataset.datalayerData)
              if (!data.user_type && document.body.dataset.userType) {
                data.user_type = document.body.dataset.userType
              }

              datalayerPush(element.dataset.datalayerEvent, data)
            }
          } else {
            if (element.dataset.datalayerEvent) {
              eArg.stopPropagation()
              eArg.preventDefault()

              const data = JSON.parse(element.dataset.datalayerData)
              if (!data.user_type && document.body.dataset.userType) {
                data.user_type = document.body.dataset.userType
              }

              datalayerPush(element.dataset.datalayerEvent, data, () => {
                document.location.href = link
              })
            } else {
              document.location.href = link
            }
          }
        })
      })
  },
  ['fragment-loaded'],
)

DOMReady(
  (event) => {
    let element = event?.target ?? document
    element
      .querySelectorAll(
        'a[data-datalayer-event]:not([data-link]):not([data-must-login=true]), button[data-datalayer-event]',
      )
      .forEach((element) => {
        element.addEventListener('click', (event) => {
          if (!element.dataset.datalayerEventSet) {
            event.stopPropagation()
            event.preventDefault()

            element.dataset.datalayerEventSet = true

            const data = JSON.parse(element.dataset.datalayerData)
            if (!data.user_type && document.body.dataset.userType) {
              data.user_type = document.body.dataset.userType
            }

            datalayerPush(element.dataset.datalayerEvent, data, () => {
              let newEvent = new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
              })
              element.dispatchEvent(newEvent)
            })
          }
        })
      })
  },
  ['fragment-loaded'],
)

DOMReady((event) => {
  if (typeof dataLayerPageViewEvent !== 'undefined') {
    datalayerPush(dataLayerPageViewEvent.event, dataLayerPageViewEvent.data)
  }
})

DOMReady(() => {
  document.querySelectorAll('#menu-searchbar a').forEach((element) => {
    element.addEventListener('click', (event) => {
      if (!element.dataset.datalayerEventSet) {
        let callback = () => {}
        if (
          element.attributes.href !== undefined &&
          !element.attributes.href.value.startsWith('#')
        ) {
          event.stopPropagation()
          event.preventDefault()

          element.dataset.datalayerEventSet = true

          callback = () => {
            let newEvent = new MouseEvent('click', {
              bubbles: true,
              cancelable: true,
            })
            element.dispatchEvent(newEvent)
          }
        }

        datalayerPush(
          'click-header-nav-menu',
          {
            user_type: document.body.dataset.userType,
            anchor_label: element.textContent.trim(),
          },
          callback,
        )
      }
    })
  })
})
