import 'js-loading-overlay'

let currentSpinner
export default {
  show: (spinnerIcon) => {
    if (this && currentSpinner) this.hide()
    JsLoadingOverlay.show({
      overlayBackgroundColor: '#E9F4FA',
      overlayOpacity: '0.8',
      spinnerIcon:
        typeof spinnerIcon === 'string'
          ? spinnerIcon
          : process.env.NODE_ENV == 'prod'
          ? 'ball-spin-clockwise'
          : 'ball-spin',
      spinnerColor: '#FF3944',
      spinnerSize: '3x',
      overlayIDName: 'overlay',
      spinnerIDName: 'spinner',
      offsetX: 0,
      offsetY: 0,
      containerID: null,
      lockScroll: true,
      overlayZIndex: 9998,
      spinnerZIndex: 9999,
    })
    currentSpinner = true
  },
  hide: () => {
    currentSpinner = false
    JsLoadingOverlay.hide()
  },
  isLoading: () => currentSpinner === true,
}
