import { Toast } from 'bootstrap'

export function emitToast(detail) {
  const event = new CustomEvent('toast', { detail })
  document.dispatchEvent(event)
}

export function listenForToast(container) {
  const toastContainer = container.querySelector('#toasts')
  if (!toastContainer) {
    return
  }

  document.addEventListener('toast', function (e) {
    if (!e.detail) {
      if (window.Sentry) {
        Sentry.captureEvent(e)
      }
      return
    }

    const el = document.createElement('div')
    el.setAttribute('class', 'toast hide')
    el.setAttribute('role', 'alert')
    el.setAttribute('aria-live', 'assertive')
    el.setAttribute('aria-atomic', 'true')

    const headerWrapper = document.createElement('div')
    headerWrapper.setAttribute('class', 'toast-header')
    if ('success' === e.detail.type) {
      headerWrapper.classList.add('text-success')
      const iconWrapper = document.createElement('div')
      iconWrapper.setAttribute('class', 'bsiw mr-2')
      const icon = document.createElement('i')
      icon.setAttribute('class', 'bi bi-check-circle-fill')
      iconWrapper.append(icon)
      headerWrapper.append(iconWrapper)
    } else if ('danger' === e.detail.type) {
      headerWrapper.classList.add('text-danger')
      const iconWrapper = document.createElement('div')
      iconWrapper.setAttribute('class', 'bsiw mr-2')
      const icon = document.createElement('i')
      icon.setAttribute('class', 'bi bi-x-lg')
      iconWrapper.append(icon)
      headerWrapper.append(iconWrapper)
    }

    const header = document.createElement('strong')
    header.setAttribute('class', 'mr-auto')
    header.innerHTML = e.detail.header
    headerWrapper.append(header)

    const closeButton = document.createElement('button')
    closeButton.setAttribute('type', 'button')
    closeButton.setAttribute('class', 'btn-close')
    closeButton.setAttribute('data-bs-dismiss', 'toast')
    closeButton.setAttribute('aria-label', 'Close')
    headerWrapper.append(closeButton)

    el.append(headerWrapper)

    if (e.detail.body) {
      const body = document.createElement('div')
      body.setAttribute('class', 'toast-body')
      body.innerHTML = e.detail.body
      el.append(body)
    }

    if (e.detail.footer) {
      const footer = document.createElement('div')
      footer.setAttribute('class', 'toast-body text-right')
      footer.innerHTML = e.detail.footer
      el.append(footer)
    }

    toastContainer.append(el)
    const toast = new Toast(el)
    toast.show()
  })
}
