export function bindToggler(link) {
  link.classList.add('cursor-pointer')
  link.classList.add('text-decoration-underline')

  link.addEventListener('click', function (eArg) {
    eArg.stopPropagation()

    let elements = document.querySelectorAll(link.dataset.selector)

    link.dataset.state = link.dataset.state === 'hidden' ? 'visible' : 'hidden'

    elements.forEach((element) => {
      if (link.dataset.state === 'hidden') {
        element.classList.add('hidden')
        link.innerText = link.dataset.showLabel
      } else {
        element.classList.remove('hidden')
        link.innerText = link.dataset.hideLabel
      }
    })

    return false
  })
}
