import '../../css/input-error.css'
import { findParent } from '../util'

/**
 * Gens and returns an (hopefully) unique ID based on the input's ID
 *
 * @param input
 * @returns {string}
 */
function getInputErrorId(input) {
  return `${input.id}_error`
}

/**
 *
 * @param input
 * @returns {string}
 */
function getInputMessageId(input) {
  return `${input.id}_message`
}

/**
 * Adds an error message to an input field
 *
 * @param {element} input The input to add an error to
 * @param {string} error The error message to display
 */
export function setError(input, error) {
  const form = findParent(input, (node) => node.tagName === 'FORM')
  form.classList.remove('was-validated')

  const errorId = getInputErrorId(input)
  const previousError = document.getElementById(errorId)
  if (previousError) {
    previousError.parentNode.removeChild(previousError)
  }

  const invalidFeedback = document.createElement('div')
  invalidFeedback.className = 'invalid-feedback'
  invalidFeedback.innerHTML = error
  invalidFeedback.id = errorId

  input.parentNode.parentNode.appendChild(invalidFeedback, input.nextSibling)
  input.parentNode.classList.add('is-invalid')
  form.classList.add('was-validated')
}

/**
 *
 * @param {element} input
 * @param {element} message
 */
export function setMessage(input, message) {
  const form = findParent(input, (node) => node.tagName === 'FORM')
  form.classList.remove('was-validated')

  const messageId = getInputMessageId(input)
  const previousMessage = document.getElementById(messageId)
  if (previousMessage) {
    previousMessage.parentNode.removeChild(previousMessage)
  }

  const validFeedback = document.createElement('div')
  validFeedback.className = 'valid-feedback'
  validFeedback.innerHTML = message
  validFeedback.id = messageId

  input.parentNode.parentNode.appendChild(validFeedback, input.nextSibling)
  input.parentNode.classList.add('is-valid')
}

/**
 * Removes an error message from an input field
 *
 * @param {element} input The input to clear the error from
 */
export function clearError(input) {
  const errorEl = document.getElementById(getInputErrorId(input))
  if (errorEl) {
    input.parentNode.parentNode.removeChild(errorEl)
  }
  input.parentNode.classList.remove('is-invalid')
}

/**
 *
 * @param {element} input
 */
export function clearMessage(input) {
  const messageEl = document.getElementById(getInputMessageId(input))
  if (messageEl) {
    input.parentNode.parentNode.removeChild(messageEl)
  }
  input.parentNode.classList.remove('is-invalid')
}
